import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { UnitSettings } from '../models/unit-settings';
import { MapSettings } from '../models/map-settings';
import { RoutingSettings } from '../models/routing-settings';
import { VoiceGuidenceSettings } from '../models/voice-guidence-settings';
import { Address } from '../models/address';

export const enum Unit {
  Metric = "metric",
  Imperial = "imperial"
}

export const enum MapSkin {
  Light = "light",
  Dark = "dark"
}

export const enum AddressType {
  Home = "home",
  Work = "work"
}

export const Language = [
  { "code": "de", "name": "Deutsch" },
  { "code": "en", "name": "English" },
  { "code": "es", "name": "Español" },
  { "code": "fr", "name": "Français" },
  { "code": "it", "name": "Italiano" },
  { "code": "nl", "name": "Nederlands" },
  { "code": "no", "name": "Norsk" },
  { "code": "se", "name": "Svenska" }
]

export const UNIT_METRIC: UnitSettings = { Distance: Unit.Metric, Temperature: Unit.Metric, Speed: Unit.Metric, Pressure: Unit.Metric };
export const UNIT_IMPERIAL: UnitSettings = { Distance: Unit.Imperial, Temperature: Unit.Imperial, Speed: Unit.Imperial, Pressure: Unit.Imperial };
export const MAP_DEFAULT: MapSettings = { Skin: MapSkin.Light, POIs: false };
export const ROUTING_DEFAULT: RoutingSettings = { TollRoads: true, Ferries: true, Highways: true };
export const VOICEGUIDENCE_DEFAULT: VoiceGuidenceSettings = { Mute: false };
export const VOICEINPUTTUTORIALDONE_DEFAULT: boolean = false;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private isAuthorized: boolean = false;
  private Unit: UnitSettings;
  public ObservableUnit: BehaviorSubject<UnitSettings>;
  private Routing: RoutingSettings;
  public ObservableRouting: BehaviorSubject<RoutingSettings>;
  private VoiceGuidence: VoiceGuidenceSettings;
  private Map: MapSettings;
  public ObservableMap: BehaviorSubject<MapSettings>;
  private VoiceInputTutorialDone: boolean;
  private Addresses: Address[] = [];
  private DisabledChargingStationIds: string[] = [];
  private FavoriteChargingStationIds: string[] = [];
  private DisabledChargingOperatorIds: number[] = [];
  private FavoriteChargingOperatorIds: number[] = [];
  private Language: string = "en";
  public ObservableLanguage: BehaviorSubject<string>;
  private Tracking: boolean = true;
  public ObservableTracking: BehaviorSubject<boolean>;

  constructor(private http: HttpClient,
    private consoleLoggerService: ConsoleLoggerService) {
    this.ObservableUnit = new BehaviorSubject<UnitSettings>(this.Unit);
    this.ObservableMap = new BehaviorSubject<MapSettings>(this.Map);
    this.ObservableLanguage = new BehaviorSubject<string>(this.Language);
    this.ObservableTracking = new BehaviorSubject<boolean>(this.Tracking);
    this.initSettings();
  }

  // load settings from localstorage
  // set default values if the localstorage values is empty
  public initSettings(): void {
    this.Addresses = [];

    let unitSettings, routingSettings, voiceguidenceSettings, mapSettings, voiceInputTutorialDoneSettings,
      disabledChargingStationsSettings, favoriteChargingStationsSettings, disabledChargingOperatorsSettings,
      favoriteChargingOperatorsSettings, languageSettings;
    try {
      unitSettings = JSON.parse(localStorage.getItem("unit"));
    } catch (e) { }
    if (typeof (unitSettings) == 'object' && unitSettings != null) {
      this.setUnit(this.unitJSONtoUnitSettings(unitSettings));
    }
    else if (unitSettings === "metric") {
      this.setUnit(UNIT_METRIC);
    }
    else if (unitSettings === "imperial") {
      this.setUnit(UNIT_IMPERIAL);
    }
    else {
      this.Unit = UNIT_METRIC;
      let checkUrl;
      if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
        checkUrl = "https://staging.evnavigation.com/ecar/check.php";
      }
      else {
        checkUrl = "https://evnavigation.com/ecar/check.php";
      }

      this.http.get(checkUrl, { responseType: 'text' }).subscribe((resp) => {
        if (resp.substring(0, 2) == "GB" || resp.substring(0, 2) == "US") {
          this.setUnit(UNIT_IMPERIAL);
        }
      });
    }

    try {
      routingSettings = JSON.parse(localStorage.getItem("routing"));
    } catch (e) { }
    if (typeof (routingSettings) == 'object' && routingSettings != null) {
      this.setRounting(this.routingJSONtoRoutingSettings(routingSettings))
    } else {
      this.setRounting(ROUTING_DEFAULT);
    }

    try {
      voiceguidenceSettings = JSON.parse(localStorage.getItem("voiceguidence"));
    } catch (e) { }
    if (typeof (voiceguidenceSettings) == 'object' && voiceguidenceSettings != null) {
      this.setVoiceGuidence(this.voiceGuidenceJSONtoVoiceGuidenceSettings(voiceguidenceSettings))
    } else {
      this.setVoiceGuidence(VOICEGUIDENCE_DEFAULT);
    }

    try {
      mapSettings = JSON.parse(localStorage.getItem("mapsettings"));
    } catch (e) { }
    if (typeof (mapSettings) == 'object' && mapSettings != null) {
      this.setMap(this.mapJSONtoMapSettings(mapSettings));
    } else {
      this.setMap(MAP_DEFAULT);
    }

    try {
      voiceInputTutorialDoneSettings = JSON.parse(localStorage.getItem("voiceInputTutorialDone"));
    } catch (e) { }
    if (voiceInputTutorialDoneSettings != null) {
      this.setVoiceInputTutorialDone(voiceInputTutorialDoneSettings)
    } else {
      this.setVoiceInputTutorialDone(VOICEINPUTTUTORIALDONE_DEFAULT);
    }

    try {
      disabledChargingStationsSettings = JSON.parse(localStorage.getItem("disabledChargingStations"));
    } catch (e) { }
    if (disabledChargingStationsSettings != null) {
      this.setDisabledChargingStationIds(disabledChargingStationsSettings);
    }

    try {
      favoriteChargingStationsSettings = JSON.parse(localStorage.getItem("favoriteChargingStations"));
    } catch (e) { }
    if (favoriteChargingStationsSettings != null) {
      this.setFavoriteChargingStationIds(favoriteChargingStationsSettings);
    }

    try {
      disabledChargingOperatorsSettings = JSON.parse(localStorage.getItem("disabledChargingOperators"));
    } catch (e) { }
    if (disabledChargingOperatorsSettings != null) {
      this.setDisabledChargingOperatorIds(disabledChargingOperatorsSettings.map(Number));
    }

    try {
      favoriteChargingOperatorsSettings = JSON.parse(localStorage.getItem("favoriteChargingOperators"));
    } catch (e) { }
    if (favoriteChargingOperatorsSettings != null) {
      this.setFavoriteChargingOperatorIds(favoriteChargingOperatorsSettings.map(Number));
    }

    try {
      languageSettings = localStorage.getItem("language");
    } catch (e) { }
    if (languageSettings != null) {
      this.setLanguage(languageSettings);
    }
  }

  public setIsAuthorized(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
  }

  public getUnit(): UnitSettings {
    return this.Unit;
  }

  public setUnit(unit: UnitSettings) {
    this.Unit = unit;
    if (!this.isAuthorized) {
      localStorage.setItem("unit", JSON.stringify(unit).toLowerCase());
    }
    this.ObservableUnit.next(unit);
  }

  public unitJSONtoUnitSettings(unitJSON): UnitSettings {
    return {
      Distance: unitJSON.distance === "imperial" ? Unit.Imperial : Unit.Metric,
      Temperature: unitJSON.temperature === "imperial" ? Unit.Imperial : Unit.Metric,
      Speed: unitJSON.speed === "imperial" ? Unit.Imperial : Unit.Metric,
      Pressure: unitJSON.pressure === "imperial" ? Unit.Imperial : Unit.Metric,
    }
  }

  public getRouting(): RoutingSettings {
    return this.Routing;
  }

  public setRounting(routing: RoutingSettings) {
    this.Routing = routing;
    if (!this.isAuthorized) {
      localStorage.setItem("routing", JSON.stringify(routing).toLowerCase());
    }
  }

  public routingJSONtoRoutingSettings(routingJSON): RoutingSettings {
    return {
      TollRoads: routingJSON.tollroads,
      Ferries: routingJSON.ferries,
      Highways: routingJSON.highways
    }
  }

  public getVoiceGuidence(): VoiceGuidenceSettings {
    return this.VoiceGuidence;
  }

  public setVoiceGuidence(voiceguidence: VoiceGuidenceSettings): void {
    this.VoiceGuidence = voiceguidence;
    if (!this.isAuthorized) {
      localStorage.setItem("voiceguidence", JSON.stringify(voiceguidence).toLowerCase());
    }
  }

  public voiceGuidenceJSONtoVoiceGuidenceSettings(voiceGuidenceJSON): VoiceGuidenceSettings {
    return {
      Mute: voiceGuidenceJSON.mute,
    }
  }

  public getMap(): MapSettings {
    return this.Map;
  }

  public setMap(map: MapSettings): void {
    this.Map = map;
    if (!this.isAuthorized) {
      localStorage.setItem("mapsettings", JSON.stringify(map).toLowerCase());
    }
    if (map.Skin === MapSkin.Dark) {
      document.body.classList.remove("lightSkin");
    }
    else {
      document.body.classList.add("lightSkin");
    }
    this.ObservableMap.next(map);
  }

  public mapJSONtoMapSettings(mapJSON): MapSettings {
    return {
      Skin: mapJSON.skin === "light" ? MapSkin.Light : MapSkin.Dark,
      POIs: mapJSON.pois
    }
  }

  public getVoiceInputTutorialDone(): boolean {
    return this.VoiceInputTutorialDone;
  }

  public setVoiceInputTutorialDone(voiceInputTutorialDone): void {
    this.VoiceInputTutorialDone = voiceInputTutorialDone;
    if (!this.isAuthorized) {
      localStorage.setItem("voiceInputTutorialDone", voiceInputTutorialDone);
    }
  }

  public getDisabledChargingOperatorIds(): number[] {
    return this.DisabledChargingOperatorIds;
  }

  public getContainsDisabledChargingOperatorId(chargingOperatorId: number): boolean {
    return this.DisabledChargingOperatorIds.includes(chargingOperatorId);
  }

  public setDisabledChargingOperatorIds(disabledChargingOperatorsList: number[]): void {
    this.DisabledChargingOperatorIds = disabledChargingOperatorsList;
    if (!this.isAuthorized) {
      localStorage.setItem("disabledChargingOperators", JSON.stringify(disabledChargingOperatorsList));
    }
  }

  public getFavoriteChargingOperatorIds(): number[] {
    return this.FavoriteChargingOperatorIds;
  }

  public getContainsFavoriteChargingOperatorId(chargingOperatorId: number): boolean {
    return this.FavoriteChargingOperatorIds.includes(chargingOperatorId);
  }

  public setFavoriteChargingOperatorIds(favoriteChargingOperatorsList: number[]): void {
    this.FavoriteChargingOperatorIds = favoriteChargingOperatorsList;
    if (!this.isAuthorized) {
      localStorage.setItem("favoriteChargingOperators", JSON.stringify(favoriteChargingOperatorsList));
    }
  }

  public getDisabledChargingStationIds(): string[] {
    return this.DisabledChargingStationIds;
  }

  public getContainsDisabledChargingStationId(chargingStationId: string): boolean {
    const disabledIndex = this.DisabledChargingStationIds.indexOf(chargingStationId);

    return disabledIndex != -1;
  }

  public setDisabledChargingStationIds(disabledChargingStationList: string[]): void {
    this.DisabledChargingStationIds = disabledChargingStationList;
    if (!this.isAuthorized) {
      localStorage.setItem("disabledChargingStations", JSON.stringify(disabledChargingStationList));
    }
  }

  public getFavoriteChargingStationIds(): string[] {
    return this.FavoriteChargingStationIds;
  }

  public getContainsFavoriteChargingStationId(chargingStationId: string): boolean {
    const favoriteIndex = this.FavoriteChargingStationIds.indexOf(chargingStationId);

    return favoriteIndex != -1;
  }

  public setFavoriteChargingStationIds(favoriteChargingStationList: string[]): void {
    this.FavoriteChargingStationIds = favoriteChargingStationList;
    if (!this.isAuthorized) {
      localStorage.setItem("favoriteChargingStations", JSON.stringify(favoriteChargingStationList));
    }
  }

  private updateChargingStationIds(): void {
    if (!this.isAuthorized) {
      localStorage.setItem("disabledChargingStations", JSON.stringify(this.DisabledChargingStationIds));
      localStorage.setItem("favoriteChargingStations", JSON.stringify(this.FavoriteChargingStationIds));
    }
  }

  public setToNormalChargingStation(chargingStationId: string): void {
    const disabledIndex = this.DisabledChargingStationIds.indexOf(chargingStationId);
    if (disabledIndex != -1) {
      this.DisabledChargingStationIds.splice(disabledIndex, 1);
    }
    const favoriteIndex = this.FavoriteChargingStationIds.indexOf(chargingStationId);
    if (favoriteIndex != -1) {
      this.FavoriteChargingStationIds.splice(favoriteIndex, 1);
    }
    this.updateChargingStationIds();
  }

  public setToDisabledChargingStation(chargingStationId: string): void {
    const favoriteIndex = this.FavoriteChargingStationIds.indexOf(chargingStationId);
    if (favoriteIndex != -1) {
      this.FavoriteChargingStationIds.splice(favoriteIndex, 1);
    }
    const disabledIndex = this.DisabledChargingStationIds.indexOf(chargingStationId);
    if (disabledIndex == -1) {
      this.DisabledChargingStationIds.push(chargingStationId);
    }
    this.updateChargingStationIds();
  }

  public setToFavoriteChargingStation(chargingStationId: string): void {
    const disabledIndex = this.DisabledChargingStationIds.indexOf(chargingStationId);
    if (disabledIndex != -1) {
      this.DisabledChargingStationIds.splice(disabledIndex, 1);
    }
    const favoriteIndex = this.FavoriteChargingStationIds.indexOf(chargingStationId);
    if (favoriteIndex == -1) {
      this.FavoriteChargingStationIds.push(chargingStationId);
    }
    this.updateChargingStationIds();
  }

  public addressesJSONtoAddresses(addresses): Address[] {
    const addressesEl: Address[] = addresses.map((address) => {
      let addressEl = new Address(
        address.AddressType === "home" ? AddressType.Home : AddressType.Work,
        address.Description,
        address.Location,
        address.BoundingBox);
      return addressEl;
    });
    return addressesEl;
  }

  public getAddresses(): Address[] {
    return this.Addresses;
  }

  public setAddresses(address: Address[]): void {
    this.Addresses = address;
  }

  public getHomeAddress(): Address {
    return this.Addresses.find((addressEl) => { return addressEl.AddressType == AddressType.Home });
  }

  public setHomeAddress(address: Address) {
    let index;
    if (this.Addresses.some((addressEl, i) => {
      index = i;
      return addressEl.AddressType === AddressType.Home;
    })) {
      this.Addresses.splice(index, 1);
    }

    this.Addresses.push(address);
  }

  public removeHomeAddress(): void {
    let index;
    if (this.Addresses.some((addressEl, i) => {
      index = i;
      return addressEl.AddressType === AddressType.Home;
    })) {
      this.Addresses.splice(index, 1);
    }
  }

  public getWorkAddress(): Address {
    return this.Addresses.find((addressEl) => { return addressEl.AddressType == AddressType.Work });
  }

  public setWorkAddress(address: Address): void {
    let index;
    if (this.Addresses.some((addressEl, i) => {
      index = i;
      return addressEl.AddressType === AddressType.Work;
    })) {
      this.Addresses.splice(index, 1);
    }

    this.Addresses.push(address);
  }

  public removeWorkAddress(): void {
    let index;
    if (this.Addresses.some((addressEl, i) => {
      index = i;
      return addressEl.AddressType === AddressType.Work;
    })) {
      this.Addresses.splice(index, 1);
    }
  }

  public getLanguage(): string {
    return this.Language;
  }

  public setLanguage(language: string): void {
    this.Language = language;
    localStorage.setItem("language", language);
    this.ObservableLanguage.next(language);
  }

  public getTracking(): boolean {
    return this.Tracking;
  }

  public setTracking(tracking: boolean) {
    this.Tracking = tracking;
    this.ObservableTracking.next(tracking);
  }

  public getLanguageList(): { code: string, name: string }[] {
    return Language;
  }
}