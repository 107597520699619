import { Component, Input, OnInit } from '@angular/core';
import device from 'current-device';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public ios: boolean = false;
  public changelogDetailed: boolean = false;
  @Input() hideChangelog: boolean = false;

  public changeLogData: { version: string, date: string, features: string[], improvements: string[], bugfixes: string[], title?: string }[] = [
    {
      version: "2.6", date: "09/16/24",
      features: ["Releasing Android Auto and CarPlay: now these are available for everyone!",
        "Replaced the charging station database provider to ecoMovement",
        "Grouping charging stations to gain more visibility on the map",
        "Added popup when charging station is reached",
        "Added “Charge here” button when a charging station is selected",
        "Supporting multi-language voice control",
        "Improve timing of lane information"],
      improvements: ["Performance upgrades",
        "Altitude chart is now removed to increase clarity of the itinerary",
        "Redesigned the header menu"],
      bugfixes: ["Fixed route planning issues",
        "Increased stability"]
    },
    {
      version: "2.5", date: "02/23/24",
      features: [],
      improvements: ["Removing SmartCar support"],
      bugfixes: ["Fixing visibility of favorit charging operators on the map"]
    },
    {
      version: "2.4", date: "02/12/24",
      features: [],
      improvements: ["Advancing navigation in Teslas"],
      bugfixes: []
    },
    {
      version: "2.3", date: "12/13/23",
      features: ["Introducing Beta program for Android Auto & CarPlay"],
      improvements: [],
      bugfixes: ["Route planning fixes"]
    },
    {
      version: "2.2", date: "10/02/23",
      features: [],
      improvements: ["Improving range calculation", "Performance enhancement"],
      bugfixes: []
    },
    {
      version: "2.1", date: "03/30/23",
      features: ["Ability to avoid or prefer charging station providers (networks)", "Drawing altitude chart for planned routes",
        "Display the estimated total consumption of the route"],
      improvements: ["Localizing voice guidance to Spanish, Italian, French and German", "Implementing a change log", "Redesign route planning mode",
        "Simplified initial login", "Made charging stations more visible on the map"],
      bugfixes: ["Speed and stability improvements"]
    },
    {
      version: "2.03", date: "07/01/22",
      features: [],
      improvements: ["Added new cars: BMW iX xDrive50 (2022), BMW i4 M50 (2021), Ford F-150 Lightning, New Kia Soul, Cupra Born, several MG, BYD types, and minivans"],
      bugfixes: []
    },
    {
      version: "2.02", date: "10/21/21",
      features: [],
      improvements: ["Improving user experience"],
      bugfixes: []
    },
    {
      version: "2.0", date: "07/29/21",
      features: ["Integration of SmartCar (stable)", "Trip history"],
      improvements: ["Implementing Keep alive function", "Improving battery consumption calculation", "Improving accessibility of the website"],
      bugfixes: []
    },
    {
      version: "1.9", date: "05/20/21",
      features: ["New feature: SmartCar integration (beta)"],
      improvements: ["Adding speed limit icon on planned route"],
      bugfixes: ["Fixing charging station popup windows"]
    },
    {
      version: "1.8", date: "03/11/21",
      features: ["Voice commands", "Disable and favor charging stations on the map", "Possibility to add Home/Work address"],
      improvements: ["Improving security"],
      bugfixes: ["Fixing mobile view issues"]
    },
    {
      version: "1.7", date: "01/13/21",
      features: [],
      improvements: ["UI improvement - implementing Light Skin", "Increasing UI accessibility"],
      bugfixes: [" Fixing registration and login issues"]
    },
    {
      version: "1.6", date: "10/20/20",
      features: [],
      improvements: ["Implementing Apple login"],
      bugfixes: []
    },
    {
      version: "1.5", date: "10/08/20",
      features: ["Account Solution (Registration, Creating user profiles, Saving own cars)", "Ability to skip charging station on route"],
      improvements: ["Updating car database"],
      bugfixes: []
    },
    {
      version: "", date: "11/25/20",
      features: [],
      improvements: [],
      bugfixes: [],
      title: "Launching iOS App"
    },
    {
      version: "", date: "05/07/20",
      features: [],
      improvements: [],
      bugfixes: [],
      title: "Launching Android App"
    },
    {
      version: "1.4", date: "05/25/20",
      features: ["Log replay"],
      improvements: ["Improving route calculation: adding shuttle bus and ferry options",
        "Improving UI: adding Settings and Info menu, enhancing map visibility, redesigning mobile view"],
      bugfixes: []
    },
    {
      version: "1.3", date: "04/25/20",
      features: ["Route planning using multiple waypoints"],
      improvements: ["Designing a new map skin (dark)", "Redesigning car selector window", "Updating car database"],
      bugfixes: ["Fixing route planning bugs"]
    },
    {
      version: "1.2", date: "03/05/20",
      features: [],
      improvements: ["Updating car database"],
      bugfixes: []
    },
    {
      version: "1.1", date: "02/27/20",
      features: ["Displaying of road lanes during navigation"],
      improvements: ["Improving route calculation", "Simplifying car selector window"],
      bugfixes: []
    },
    {
      version: "1.0", date: "02/12/20",
      features: [],
      improvements: [],
      bugfixes: [],
      title: "Launching EVNavigation"
    }
  ];

  constructor(public settingsService: SettingsService, public languageService: LanguageService) {
    if (device.ios()) {
      this.ios = true;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  public openUrl(url): void {
    window.open(url, '_blank');
  }
}
